import zipObject from 'lodash-es/zipObject'

interface PartnerData {
  jwt?: string
  id?: PartnerId
  extra?: string
  directEncashment?: boolean
  language?: string
  salesoffice?: string
}

type PartnerCookies = {
  jwt?: MaybeRef<string | null | undefined>
  partner?: MaybeRef<PartnerCookiesPartner | null | undefined>
}

type PartnerCookiesPartner = { id?: PartnerId; extra?: string }

export const usePartnerStore = defineStore('partner', () => {
  const params = useParams()
  const cookies = usePartnerCookies()

  const jwt = ref('')
  const id = ref('')
  const extra = ref('')
  const directEncashment = ref(false)
  const language = ref('')
  const salesoffice = ref('')

  async function init() {
    const partner = await getPartner(params.persistent, cookies, useRequestHeaders())

    jwt.value = partner.jwt ?? ''
    id.value = partner.id ?? ''
    extra.value = partner.extra ?? ''
    directEncashment.value = partner.directEncashment ?? false
    language.value = partner.language ?? ''
    salesoffice.value = partner.salesoffice ?? ''

    cookies.update(partner)
    if (partner.id) params.persistent.partnerid = partner.id
    if (partner.id && partner.extra) params.persistent.partnerextra = partner.extra
  }

  return { jwt, id, extra, directEncashment, salesoffice, language, init }
})

function usePartnerCookies() {
  const options = { secure: true, sameSite: 'none' as 'none', path: '/', httpOnly: true }

  const jwt = useCookie('jwt', { ...options, expires: hoursAdd(new Date(), 12) })

  const partner = useCookie<PartnerCookiesPartner | null | undefined>('partner', {
    ...options,
    expires: daysAdd(new Date(), 30),
    decode: (raw) => zipObject(['id', 'extra'], raw.split('&') ?? []),
    encode: (value) => (value?.id ? [value.id, value?.extra].filter(Boolean).join('&') : ''),
  })

  // Sanitizing `partner` cookie
  const validPartnerId = getPartnerId(partner.value?.id)
  partner.value = validPartnerId ? { ...partner.value, id: validPartnerId } : undefined

  function update({ jwt: _jwt, id, extra }: PartnerData) {
    if (_jwt) jwt.value = _jwt
    else if (id) partner.value = { id, extra }
  }

  return { jwt, partner, update }
}

/**
 * Gets partner data by checking it in order:
 * 1. JWT
 * 2. URL Parameters
 * 3. Referrer
 * 4. Cookie
 *
 * @see https://jira.migros.net/browse/HHDWEBCC-4617
 */
async function getPartner(params: PlainParams, cookies: PartnerCookies, headers: Record<string, string>): Promise<PartnerData> {
  const jwt = toValue(cookies.jwt) || params.jwt?.toString()

  if (jwt) return await getPartnerFromJwt(jwt)

  const [id, extra] = [params.partnerid, params.partnerextra].map((value) => value?.toString() || undefined)
  const partner = { id, extra }
  if (!partner.id || !PARTNER_ID_WHITELIST.has(partner.id)) {
    partner.id = getPartnerIdForReferrer(headers.referer) ?? partner.id
  }

  return partner.id ? partner : (toValue(cookies.partner) ?? {})
}

async function getPartnerFromJwt(jwt: string) {
  const { data } = await useWebccApi().partner(jwt)

  return { ...(data.value ?? {}), jwt }
}

function getPartnerIdForReferrer(referrer: string): string | undefined {
  if (!referrer) return

  return Object.entries({
    IH1000116: ['google.'],
    IH1000117: ['bing.', 'ecosia.org', 'duckduckgo.', 'yahoo.', 'baidu.', 'seznam.', 'qwant.com', 'yandex.'],
  }).find(([, substrings]) => substrings.some((substring) => referrer.includes(substring)))?.[0]
}

/**
 * @see https://jira.migros.net/browse/HHDWEBCC-4679
 */
const PARTNER_ID_WHITELIST: ReadonlySet<string> = new Set([
  'IH1000019',
  'IH1000020',
  'IH1000021',
  'IH1000022',
  'IH1000023',
  'IH1000024',
  'IH1000025',
  'IH1000026',
  'IH1000027',
  'IH1000028',
  'IH1000029',
  'IH1000030',
  'IH1000031',
  'IH1000032',
  'IH1000033',
  'IH1000034',
  'IH1000035',
  'IH1000036',
  'IH1000223',
  'CH1001778',
])
