export default defineNuxtRouteMiddleware(async (to, from): Promise<RedirectOut | any> => {
  const TAG = `[Middleware] conf loading ${new Date().toISOString()}`

  console.time(TAG)
  const result = await useConfdata().load(to)
  console.timeEnd(TAG)

  if (!result) {
    return
  }

  if (result instanceof Error) {
    console.error(`${TAG} failed ${useRequestURL()}:`, result.message || result)
    throw result
  }

  if ('location' in result) {
    const { location, status } = result as RedirectOut
    const accommodationRegexp = /([a-zA-Z]{2}\d{4}\.\d{1,4}\.\d{1,3})/
    // In cases where we redirect from a detail page, we want the user to be able to try to access that page again in the future.
    // See https://jira.migros.net/browse/IHGWEBCC-36
    if (import.meta.server && to.path.match(accommodationRegexp) && !from.path.match(accommodationRegexp)) {
      setHeader(useNuxtApp().ssrContext!.event, 'Cache-Control', 'max-age=86400')
    }
    return await navigateTo(location, { redirectCode: status || 301 })
  }
})
