<template>
  <div class="bg-bgr-50">
    <div class="container-fluid-lg flex flex-row-reverse flex-wrap items-baseline">
      <div class="ml-2 flex py-2.5">
        <client-only>
          <WebccButton
            v-if="actions.includes('bookmark') && useStorageReady()"
            :variant="isBookmarked ? 'theme' : ''"
            icon="site/bookmark"
            :icon-filled="true"
            class="flex items-center"
            size="md"
            @click="
              () => {
                const data = { ...accommodation, ...price, ...trip } as TranslatedAccommodationWithPrice
                if (!data.price || !data.checkin || !data.checkout) {
                  data.price = minPrice?.price
                  data.regularPrice = minPrice?.regularPrice
                  data.checkin = tryParseDate(minPrice?.checkin) as Date & string
                  data.checkout = tryParseDate(minPrice?.checkout) as Date & string
                }
                if (isBookmarked) {
                  tracking.handlers?.ecommerce.removeFromWishlist([data])
                  tracking.handlers?.detail.deleteFromWishlist(accommodation!.code)
                } else {
                  tracking.handlers?.ecommerce.addToWishlist([data])
                  tracking.handlers?.detail.addToWishlist(accommodation!.code)
                }
                toggleBookmark()
              }
            "
          >
            <span>{{ $t(`${trPrefix}${isBookmarked ? 'bookmarkDel' : 'bookmarkAdd'}`) }}</span>
          </WebccButton>
        </client-only>
        <DetailsSocialShare v-if="actions.includes('share') && showSocialShare" class="ml-2" @open="tracking.handlers?.detail.openRecommendationLayer()" />
      </div>
      <DetailsBreadcrumbs v-if="showBreadcrumbs" :accom="accommodation!" :language-prefix="languagePrefix" class="flex-grow" />
    </div>

    <div class="container-fluid-lg relative">
      <DetailsGallery
        v-if="noFacettedGallery"
        :checkin="trip.checkin"
        class="mb-4 aspect-[4/3] rounded bg-bgr-100 shadow sm:aspect-[2/1]"
        :accom="accommodation!"
        :cover-full="false"
        :screen-size="screenSize"
        @open-gallery="toggleGallery($event, 'imageClick')"
      />
      <FacettedGallery
        v-else
        :checkin="trip.checkin"
        class="rounded"
        :accom="accommodation"
        :screen-size="screenSize"
        @open-gallery-modal="toggleGallery($event, 'imageClick')"
      />
      <div v-if="Object.keys(accommodation!.media.items).length > 0" class="flex w-full justify-center sm:absolute sm:bottom-4 sm:left-4 sm:w-48">
        <WebccButton variant="theme" outline class="w-48" size="md" @click="toggleGallery('', 'allImages')">
          <span>{{ $t('www.components.views.details.ModalGallery.open') }}</span>
        </WebccButton>
      </div>
    </div>

    <div class="container-fluid-lg">
      <DetailsModalGallery
        v-if="galleryModalOpen"
        :accom="accommodation!"
        :checkin="trip.checkin"
        :scroll-to-img="scrollToImg"
        :is-bookmarked="isBookmarked"
        @close="toggleGallery('', '')"
        @toggle-bookmark="toggleBookmark()"
      />
      <DetailsTitle :accom="accommodation!" class="mb-1.5 mt-4 md:mb-4 md:w-2/3" />
    </div>

    <div class="container-fluid-lg items-stretch justify-between md:flex">
      <main class="md:w-2/3">
        <div
          v-if="accommodation && accommodation.rating.overall > 0"
          class="mb-3 mt-1 flex cursor-pointer flex-row items-center justify-start gap-1 text-sm font-bold text-thm md:hidden"
          @click="scrollto('reviewsRef')"
        >
          <span class="flex">
            <WebccIcon
              name="reviews/heart"
              class="h-3 w-3"
              role="img"
              aria-hidden="true"
              focusable="false"
              :alt="formatNumber(accommodation.rating.overall, '1-digit') + '/ 5'"
            />
          </span>
          <span class=""> {{ formatNumber(accommodation.rating.overall, '1-digit') }} / 5 </span>
          <span class="flex items-center">
            (
            <span>{{ $t(`${trPrefix}review_s`, accommodation.rating.numReviews) }}</span>
            )
          </span>
        </div>

        <section class="mb-4 flex flex-col items-center">
          <div v-if="showDescriptionsDerived" class="mb-8 w-full bg-bgr px-4 py-6 font-semibold shadow">{{ descriptionsDerived.headline }}</div>
          <DetailsFeatures class="w-full" :accom="accommodation!" />
        </section>

        <DetailsAttributes
          v-track:impression="'detail:attributeImpression'"
          :themes="accommodation?.themes"
          :attributes="accommodation?.attributes"
          :distances="accommodation?.distances"
        />
        <div v-if="alerts.length > 0" class="relative mt-4 space-y-4 rounded border-0 bg-bgr p-4 text-sm leading-4 shadow md:leading-5">
          <Alert v-for="(alert, i) in alerts" :key="i" alert-type="info" content-type="text" :title="alert.title" :content="alert.content"></Alert>
        </div>

        <WebccHeading :title="$t(`${trPrefix}headings.description`)" :level="3" icon="detailpage/document" />
        <!-- <DetailsDescription v-track:impression="'detail:descriptionImpression'" :descriptions="accommodation?.descriptions" :check-in="trip.checkin" /> -->
        <component
          :is="showDescriptionsDerived ? 'WebccExpandable' : 'span'"
          :class="showDescriptionsDerived ? 'w-full bg-bgr px-5 py-6 shadow' : ''"
          @expand="() => (showDescriptionsDerived ? tracking.handlers?.detail.showDescriptionText() : undefined)"
        >
          <div v-if="showDescriptionsDerived" class="mb-8 leading-7">{{ descriptionsDerived.description }}</div>
          <DetailsDescription v-track:impression="'detail:descriptionImpression'" :descriptions="accommodation?.descriptions" :checkin="trip.checkin" />
        </component>

        <DetailsResidence :accom="accommodation" />

        <DetailsDestinationDescription :place="accommodation?.place" />

        <WebccHeading :title="$t(`${trPrefix}headings.map`)" :level="3" icon="detailpage/map" />
        <DetailsMap v-track:impression="'detail:mapImpression'" :accom="accommodation!" />

        <template v-if="vacancies">
          <WebccHeading id="vacancies" class="hidden md:flex" :title="$t(`${trPrefix}headings.availability`)" :level="3" icon="site/calendar" />
          <DetailsVacancy
            ref="vacanciesRef"
            class="w-full rounded p-2 md:p-0 md:shadow"
            :vacancies="vacancies"
            :pax-up-to="paxUpTo"
            :model-value="trip"
            @update:model-value="onUpdateTrip"
          />
        </template>

        <WebccHeading :title="$t(`${trPrefix}headings.costoverview`)" :level="3" icon="detailpage/document" />
        <DetailsServices
          id="costs"
          ref="costsRef"
          v-track:impression="'detail:costOverviewImpression'"
          :links="navigationLinks!"
          :services="services"
          :loading="false"
        />

        <template v-if="cancelConditions.length">
          <!-- TODO: ab test später wieder rein? -->
          <WebccHeading :title="$t('www.components.views.details.CancelConditions.heading')" :level="3" icon="detailpage/document" />
          <DetailsCancelConditions v-if="trip.checkin" :conditions="cancelConditions" :trip="{ checkin: new Date(trip.checkin) }" />
        </template>

        <WebccHeading :title="$t('www.components.views.PageDetails.headings.reviews')" :level="3" icon="detailpage/like" />
        <DetailsReviews
          id="reviews"
          ref="reviewsRef"
          v-track:impression="'detail:reviewsImpression'"
          :code="accommodation!.code"
          :rating="accommodation?.rating"
          :reviews="[]"
        />
        <template v-if="showLabels && (casa || swisspeakResort || swissTourism || lso)">
          <WebccHeading :title="$t(`${trPrefix}headings.advantages`)" :level="3" />
          <DetailsLabelInfo
            :accom="accommodation"
            :content="(useConfdata().additionalBody as DetailpageBody).badges"
            :casa="casa"
            :lso="lso"
            :swisspeak-resort="swisspeakResort"
            :swiss-tourism="swissTourism"
          />
        </template>
      </main>

      <aside class="hidden md:block md:w-1/3 md:pl-6">
        <DetailsRating
          :code="accommodation?.code"
          :rating="accommodation?.rating.overall"
          :num-reviews="accommodation?.rating.numReviews"
          class="pr-2"
          @scroll-to-reviews="scrollto('reviewsRef')"
        />
        <div class="sticky top-4">
          <DetailsSidebarAvailabilityCard
            ref="sidebarRef"
            :accommodation="accommodation"
            :state="state"
            :free-cancellation-range="freeCancellationRange"
            :price="minPrice || price"
            :option-possible="optionPossible"
            :pax-up-to="paxUpTo"
            :vacancies="vacancies"
            :model-value="trip"
            @update:model-value="onUpdateTrip"
            @book="book"
            @scrollto="scrollto"
          />
          <DetailsSidebarContactCard v-if="showContactCard" :phone="salesoffice!.phone" :email="salesoffice!.email" />
        </div>
      </aside>
    </div>

    <DetailsBottomAvailabilityCard
      class="fixed bottom-0 z-5"
      :accommodation="accommodation"
      :state="state"
      :free-cancellation-range="freeCancellationRange"
      :price="minPrice || price"
      :option-possible="optionPossible"
      :pax-up-to="paxUpTo"
      :vacancies="vacancies"
      :model-value="trip"
      @update:model-value="onUpdateTrip"
      @book="book"
      @scrollto="scrollto"
    />
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import type { TranslatedAccommodationWithPrice } from '~/resources/tracking/ecommerce'

const trPrefix = 'www.components.views.PageDetails.'

type ActionType = 'bookmark' | 'share'

withDefaults(
  defineProps<{
    showLabels?: boolean
    showContactCard?: boolean
    showBreadcrumbs?: boolean
    actions?: ActionType[]
  }>(),
  {
    showLabels: true,
    showContactCard: true,
    showBreadcrumbs: true,
    actions: () => ['bookmark', 'share'],
  },
)

const { languagePrefix, salesoffice } = storeToRefs(useConfdata())
const tracking = useTracking()
const vacancies = ref<Trips>()
const queryParams = useParams().all

const showSocialShare = computed(() => {
  return !useParams().iframe
})

const accommodation = computed(() => useConfdata().baseData!)
await loadAccommodation()
const trip = ref<TripUpdate>({
  checkin: tryParseDate(queryParams.checkin?.toString()),
  checkout: tryParseDate(queryParams.checkout?.toString()),
  pax: tryParseInt(queryParams.pax?.toString()) ?? vacancies.value?.minPriceTrip?.paxUpTo ?? 1,
})
const screenSize = ref<ScreenSize>('sm')
const galleryModalOpen = ref(false)
const scrollImg = ref('')

const vacanciesRef = ref()
const costsRef = ref()
const reviewsRef = ref()
const sidebarRef = ref()

const minPrice: ComputedRef<AccomPrice | null> = computed(() => {
  if (trip.value?.checkin || !vacancies.value?.minPriceTrip) return null

  return {
    price: vacancies.value?.minPriceTrip.price,
    reduction: vacancies.value!.minPriceTrip.regular! - vacancies.value.minPriceTrip.price!,
    regularPrice: vacancies.value?.minPriceTrip.regular,
    checkin: vacancies.value?.minPriceTrip.checkin,
    checkout: toDate(calculateCheckoutDate(vacancies.value!.minPriceTrip.checkin!, vacancies.value!.minPriceTrip.duration!)),
  }
})

const checkPrice = useCheckPrice(minPrice)
const { state, price, optionPossible, services, cancelConditions } = checkPrice

const navigationLinks = computed(() => useConfdata().links)
const season = computed(() => useSeason(trip.value!.checkin))
const scrollToImg = computed(() => scrollImg.value)
const freeCancellationRange = computed(() => getFreeCancellationRange(cancelConditions.value))
const isBookmarked = computed(() => useBookmarks().raw.includes(accommodation.value!.code))

const noFacettedGallery = computed(() => {
  const key = `images_${season.value}`
  return !screenSize.value || (accommodation.value?.media[key as keyof Media] as string[]).length <= 3
})

const paxUpTo = computed(() => {
  const validities = accommodation.value?.pax.validities ?? []
  const checkin = trip.value.checkin && toServerDate(trip.value.checkin)

  const [validity] =
    validities.length > 1 && checkin
      ? validities.filter(({ from, to }) => (!from && checkin <= to) || (from <= checkin && checkin <= to) || (from <= checkin && !to))
      : validities

  return validity?.value ?? accommodation.value?.pax.capacity
})

const alerts = computed(() => {
  return checkPrice.alerts.value.map(({ validFrom, validTo, description }) => ({
    title: 'www.components.ui.alert.importantObjectNote',
    content: new Date(validFrom) + ' - ' + new Date(validTo) + ': ' + description,
  }))
})

const casa = computed(() => {
  return accommodation.value?.themes.includes('casa') ?? false
})

const swisspeakResort = computed(() => {
  return accommodation.value?.themes.includes('swiss_peak') ?? false
})

const swissTourism = computed(() => {
  return accommodation.value?.country.code === 'CH' && accommodation.value.evaluation.stars > 0
})

const lso = computed(() => {
  return !!(accommodation.value?.buyingOfficeCode && accommodation.value.buyingOfficeCode !== '000' && accommodation.value.buyingOfficeCode.length === 5)
})

const descriptionsDerived = computed(() => {
  return {
    headline: accommodation.value?.descriptionsDerived?.headline,
    description: accommodation.value?.descriptionsDerived?.description,
  }
})

const showDescriptionsDerived = computed(() => {
  return useAB().isActive('chatGPT_PDP') && descriptionsDerived.value.headline && descriptionsDerived.value.description
})

if (trip.value?.checkin) await getCheckPriceFinal()

onMounted(() => {
  nextTick(() => {
    const tripValue = { ...trip.value }
    if (!tripValue.checkin || !tripValue.checkout) {
      delete tripValue.checkin
      delete tripValue.checkout
    }
    tracking.handlers?.ecommerce.viewItem({
      ...accommodation.value,
      ...(price.value?.price ? price.value : minPrice.value),
      ...tripValue,
    } as TranslatedAccommodationWithPrice)
    tracking.handlers?.eec.productDetailImpression({ ...(price.value?.price ? price.value : minPrice.value), ...tripValue })
  })
})

async function loadAccommodation() {
  vacancies.value = (await useAccommodation(accommodation.value.code).getVacancies()) ?? undefined
}

async function getCheckPriceFinal() {
  const { code } = accommodation.value ?? {}
  const { checkin, checkout, pax } = trip.value
  if (!code || !checkin || !checkout || !pax) return

  await checkPrice.loadFinal(code, checkin, checkout, pax)
  tracking.handlers?.ecommerce.viewItem({ ...accommodation.value, ...price.value, ...trip.value } as TranslatedAccommodationWithPrice)
}

async function onUpdateTrip({ checkin, checkout, pax }: TripUpdate) {
  if (checkin) trip.value!.checkin = checkin
  if (checkout) trip.value!.checkout = checkout
  if (pax) {
    trip.value!.pax = pax
    tracking.handlers?.detail.paxSelected(pax)
  }
  if (!trip.value.pax || (paxUpTo.value && paxUpTo.value < trip.value.pax!)) {
    trip.value.pax = paxUpTo.value
  }
  updateURL()
  await getCheckPriceFinal()
}

function updateURL() {
  const { checkin, checkout, pax } = trip.value
  useNavigation().changeQuery({
    checkin: checkin && toDate(checkin),
    checkout: checkout && toDate(checkout),
    pax: pax && checkin && checkout ? pax : undefined,
    partnerid: usePartner().id,
    ...useParams().persistent,
  })
}

function book(options: BookingOptions) {
  if (!isTripValid(trip.value)) return

  const booking = {
    ...createBookingPending(accommodation.value, trip.value, options),
    // Saving the booking URL for compatibility with webсс-booking.
    // It would probably be better to store only the booking data and create the URL dynamically.
    url: useURLs().buildBookingUrl(accommodation.value, trip.value, options).toString(),
  }
  useBookingsPending().save(booking)
  window.location.href = booking.url
}

function scrollto(section: string) {
  console.log('scrollto', section)

  switch (section) {
    case 'vacanciesRef':
      vacanciesRef.value?.$el.scrollIntoView({ behaviour: 'smooth' })
      break
    case 'costsRef':
      costsRef.value?.$el.scrollIntoView({ behaviour: 'smooth' })
      break
    case 'reviewsRef':
      reviewsRef.value?.$el.scrollIntoView({ behaviour: 'smooth' })
      break
    case 'sidebarRef':
      sidebarRef.value?.$el.scrollIntoView({ behaviour: 'smooth' })
      break
    default:
      break
  }
}

function toggleBookmark() {
  useBookmarks().toggle(accommodation.value!.code)
}

function toggleGallery(img: string, eventName: string) {
  galleryModalOpen.value = !galleryModalOpen.value
  scrollImg.value = img
  if (galleryModalOpen) {
    document.body.classList.add('overflow-hidden')
  } else {
    document.body.classList.remove('overflow-hidden')
  }

  if (eventName !== '') {
    tracking.handlers?.detail[eventName as 'imageClick' | 'allImages']()
  }
}
</script>
