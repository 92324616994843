<template>
  <article class="relative flex flex-col rounded bg-bgr shadow">
    <header class="relative">
      <GoogleMaps
        v-if="mapVisibilityState"
        ref="map"
        :locale="locale"
        :domain="domain"
        :center="{ lat: accom.coords.lat, lng: accom.coords.lon }"
        :marker="accom.name"
        :ui="false"
        class="aspect-video rounded-t"
      />
      <HitboxGallery :transformations="transformations" class="gallery aspect-[4/3]" :accom="accom" :checkin="checkin" @open="toDetailpage" />

      <div>
        <div class="absolute flex flex-row items-center bottom-0 left-0 rounded-tr bg-white/80 px-4 text-txt backdrop-blur">
          <template v-if="!legalinfo && accom.trip?.price">
            <!-- TODO: ACHTUNG: Preise sind immer in EUR. Umrechnungskurse fehlen noch -->
            <span class="text-lg font-bold">
              <div v-if="useFlag('SRP-display-price-per-night').value && accom.trip.pricePerNight">
                <small class="font-light"
                  ><span class="mr-1">{{ $t(`${trPrefix}Price.from`) }}</span></small
                >
                <span class="text-lg font-bold">{{ formatCurrency(accom.trip.pricePerNight, undefined, currency) }}</span>
                <small class="font-light"
                  ><span class="mr-1"> / {{ $t(`${trPrefix}Price.night`) }}</span>
                </small>
                <small class="font-light"
                  ><span>| </span>
                  <span class="font-medium">{{ formatCurrency(accom.trip.price, undefined, currency) }}</span>
                  <span>&nbsp;{{ $t(`${trPrefix}Price.total`) }}</span>
                </small>
              </div>
              <div v-else>
                <span class="mr-1">{{ $t(`${trPrefix}Price.from`) }}</span>
                {{ formatCurrency(accom.trip.price, undefined, currency) }}
                <small class="font-light">
                  / <span>{{ $t(`${trPrefix}Price.night_s`, accom.trip.duration) }}</span>
                </small>
              </div>
            </span>

            <span v-tooltip="{ content: tooltip, html: true }" class="cursor-help">
              <WebccIcon name="site/info" class="mt-0.5 inline-block h-4 w-4 text-thm ml-2" />
            </span>
          </template>
          <template v-else>
            <div class="flex items-center font-semibold uppercase tracking-wide text-thm">
              <WebccIcon name="attributes/feature-pax" class="mx-1 h-5 w-5 fill-current" /> {{ accom.pax }} &bull;
              <WebccIcon name="attributes/feature-rooms" class="mx-1 h-5 w-5 fill-current" /> {{ accom.rooms }} &bull;
              <WebccIcon name="attributes/feature-bedrooms" class="mx-1 h-5 w-5 fill-current" /> {{ accom.bedrooms }}
            </div>
          </template>
        </div>

        <div
          v-if="useFlexTrip().flexibleCalendar && checkin && checkout && accom.trip?.checkin && accom.trip?.checkout"
          class="absolute bottom-9 left-4 inline-flex items-center justify-center gap-[10px] rounded bg-bgr p-2 opacity-80"
        >
          <span class="h-[14px] text-xs font-medium">
            {{ formatDatetime(accom.trip.checkin, 'monthDay') }} - {{ formatDatetime(accom.trip.checkout, 'monthDay') }}
          </span>
        </div>

        <button class="absolute right-2 top-2 rounded-full bg-black/40 p-1.5 hover:bg-black/50" @click.stop.prevent="mapVisibilityState = !mapVisibilityState">
          <svg
            v-if="!mapVisibilityState"
            class="h-5 w-5 fill-none stroke-2 text-txt-weakest"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <polygon points="1 6 1 22 8 18 16 22 23 18 23 2 16 6 8 2 1 6"></polygon>
            <line x1="8" y1="2" x2="8" y2="18"></line>
            <line x1="16" y1="6" x2="16" y2="22"></line>
          </svg>
          <svg
            v-if="mapVisibilityState"
            class="h-5 w-5 fill-none stroke-2 text-txt-weakest"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <rect x="3" y="3" width="7" height="7"></rect>
            <rect x="14" y="3" width="7" height="7"></rect>
            <rect x="14" y="14" width="7" height="7"></rect>
            <rect x="3" y="14" width="7" height="7"></rect>
          </svg>
        </button>
        <client-only v-if="useStorageReady()">
          <button class="absolute right-12 top-2 rounded-full bg-black/40 p-1.5 hover:bg-black/50" @click.stop.prevent="useBookmarks().toggle(accom.code)">
            <WebccIcon :name="isBookmarked ? 'site/bookmark-filled' : 'site/bookmark'" class="h-5 w-5 stroke-2 text-txt-weakest" filled />
          </button>
        </client-only>
        <button v-if="mapVisibilityState" class="absolute bottom-16 right-2 rounded-full bg-black/40 p-1.5 hover:bg-black/50" @click.stop.prevent="zoom(1)">
          <svg
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="h-5 w-5 fill-none text-txt-weakest"
          >
            <line x1="12" y1="5" x2="12" y2="19"></line>
            <line x1="5" y1="12" x2="19" y2="12"></line>
          </svg>
        </button>
        <button v-if="mapVisibilityState" class="absolute bottom-6 right-2 rounded-full bg-black/40 p-1.5 hover:bg-black/50" @click.stop.prevent="zoom(-1)">
          <svg
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="h-5 w-5 fill-none text-txt-weakest"
          >
            <line x1="5" y1="12" x2="19" y2="12"></line>
          </svg>
        </button>
        <HitboxTags class="absolute -left-2 top-2" :tags="accom.tags || []" :discount="discount" />
      </div>
    </header>
    <div class="flex grow flex-col p-2">
      <HitboxBreadcrumb v-if="accom.slug" :links="breadcrumbLinks" class="text-dim" :accom="accom" hide-code size="xs" :target-url="targetUrl" />
      <HitboxTitle size="sm" :accom="accom" :target-url="targetUrl" @click="toDetailpage" />

      <div class="mt-auto">
        <div class="mt-1 text-xs">
          <div v-if="accom.rating > 0" class="flex flex-row items-center justify-end gap-1 font-bold text-thm">
            <span class="flex text-thm">
              <WebccIcon
                :filled="false"
                name="reviews/heart"
                class="h-3 w-3 text-thm"
                role="img"
                aria-hidden="true"
                focusable="false"
                :alt="accom.rating + '/ 5'"
              />
            </span>
            <span class=""> {{ formatNumber(accom.rating, '1-digit') }} / 5 </span>
            <span class="flex items-center">
              (
              <span>{{ $t(`${trPrefix}Rating.review_s`, accom.reviews) }}</span>
              )
            </span>
          </div>
        </div>
        <WebccFreeCancellationInfo
          v-if="checkin"
          v-track:impression="{ event: 'search:freeCancellationImpression' }"
          :checkin-date="checkin"
          :free-cancellation-range="useConfdata().freeCancellationRange"
          :locale="useL10N().locale"
        />

        <div v-if="accom.trip" class="flex justify-between pt-2 text-thm">
          <div v-if="!legalinfo" class="flex items-center text-xs font-semibold uppercase tracking-wide text-thm">
            <WebccIcon name="attributes/feature-pax" class="mx-1 h-5 w-5 fill-current" /> {{ accom.pax }} &bull;
            <WebccIcon name="attributes/feature-rooms" class="mx-1 h-5 w-5 fill-current" /> {{ accom.rooms }} &bull;
            <WebccIcon name="attributes/feature-bedrooms" class="mx-1 h-5 w-5 fill-current" /> {{ accom.bedrooms }}
          </div>
          <div v-else-if="accom.trip.price">
            <span class="mr-1">{{ $t(`${trPrefix}Price.from`) }}</span>
            <span class="text-lg font-bold"
              >{{ formatCurrency(accom.trip.price, undefined, currency) }}
              <small class="font-light">
                / <span>{{ $t(`${trPrefix}Price.night_s`, accom.trip.duration) }}</span>
              </small></span
            >
          </div>
          <WebccButton variant="cta" size="sm" class="" @click="toDetailpage">
            <span>{{ $t(`${trPrefix}discover`) }}</span>
          </WebccButton>
        </div>
        <div v-if="legalinfo">
          <span v-tooltip="{ content: tooltip, html: true }" class="cursor-help">
            <span>{{ $t(`${trPrefix}Price.addCosts`) }}</span>
            <WebccIcon name="site/info" class="-mt-0.5 inline-block h-4 w-4 text-thm" />
          </span>
        </div>
      </div>
      <!--  -->
    </div>
  </article>
  <!-- </WebccLink> -->
</template>

<script setup lang="ts">
import GoogleMaps from '~/components/common/functional/GoogleMaps.vue'
const trPrefix = 'www.components.hitbox.'

const { t } = useI18n()

const emit = defineEmits<{ 'to-detailpage': [] }>()

const props = withDefaults(
  defineProps<{
    accom: ResultDocument
    currency: string
    languagePrefix?: string
    locale?: string
    domain?: string
    checkin?: Date
    checkout?: Date
    pax?: number
    breadcrumbLinks?: boolean
    transformations?: Record<string, string>
  }>(),
  {
    languagePrefix: '',
    checkin: undefined,
    checkout: undefined,
    pax: 1,
    locale: '',
    domain: '',
    breadcrumbLinks: true,
    transformations: () => {
      return { xl: 'tr:n-srp_hitbox_sm', lg: 'tr:n-srp_hitbox_sm', md: 'tr:n-srp_hitbox_l' }
    },
  },
)

const map: Ref<typeof GoogleMaps | null> = ref(null)

const mapVisibilityState = ref(false)

const targetUrl = computed(() => useURLs().buildAccommodationUrl(props.accom))

const isBookmarked = computed(() => {
  return useBookmarks().raw.includes(props.accom.code)
})

const discount = computed(() => props.accom.trip?.reduction ?? 0)

const legalinfo = computed(() => {
  return useAB().isActive('legalinfo')
})

const tooltip = computed(() => {
  const dependentPrice = t(`${trPrefix}Price.dependentPrice`)
  const priceAfterInput = t(`${trPrefix}Price.priceAfterInput`)
  const addCosts = t(`${trPrefix}Price.addCosts`)
  const addCostsLong = t(`${trPrefix}Price.addCostsLong`)

  return `<div class='max-w-[12rem] p-1'><strong>${dependentPrice}</strong> <p class='mb-2'>${priceAfterInput}</p> <strong>${addCosts}</strong> <p class='mb-2'>${addCostsLong}</p></div>`
})

function toDetailpage() {
  emit('to-detailpage')
  useTracking().handlers?.eec.productClick(props.accom)
  useTracking().handlers?.ecommerce.selectItem(props.accom)
  window.open(targetUrl.value, useParams().iframe ? '_self' : undefined)
}

function zoom(delta: number) {
  map.value?.zoom(delta)
}
</script>

<style scoped>
.hitboxlayout {
  grid-template-columns: minmax(275px, 1fr) 2fr;
  grid-template-areas:
    'image features'
    'footerActions price'
    'map map';
}
/* } */

.gallery {
  grid-area: image;
}
.badges-grid {
  grid-area: badges;
}
.rating-grid {
  grid-area: rating;
}
.breadcrumb-grid {
  grid-area: breadcrumb;
}
.title-grid {
  grid-area: title;
}
.features-grid {
  grid-area: features;
}
.actions-grid {
  grid-area: footerActions;
}
.price-grid {
  grid-area: price;
}
.cta-grid {
  grid-area: footerCta;
}
.accommodation-map {
  grid-area: map;
}
</style>
