import merge from 'lodash-es/merge'
import getByPath from 'lodash-es/get'
import type { RecursiveKeyOf, TranslationsType } from '~/translations/master'

export interface Translations {
  [key: string]: string | Translations
}

type TranslationKeyType = RecursiveKeyOf<typeof TranslationsType>
export type { TranslationKeyType as TranslationKey }

declare global {
  type TranslationKey = TranslationKeyType
}

export interface TranslationParams {
  [key: string]: string
}

export type TranslateFn = {
  /**
   * Mimics {@link https://vue-i18n.intlify.dev/api/injection.html#t-key-plural | `$t(key, plural)`} function of {@link https://github.com/intlify/vue-i18n | @intlify/vue-i18n}.
   *
   * @todo Remove this function after final transition to {@link https://github.com/intlify/vue-i18n | @intlify/vue-i18n}.
   */
  (key: TranslationKey, plural?: number): string
  /**
   * Mimics {@link https://vue-i18n.intlify.dev/api/injection.html#t-key-named-plural | `$t(key, named, plural)`} function of {@link https://github.com/intlify/vue-i18n | @intlify/vue-i18n}.
   *
   * @todo Remove this function after final transition to {@link https://github.com/intlify/vue-i18n | @intlify/vue-i18n}.
   */
  (key: TranslationKey, named?: TranslationParams, plural?: number): string
  (key: TranslationKey, params?: number | TranslationParams, plural?: number): string
}

export type L10NContext = {
  locale: string
  language: string
  translations: typeof TranslationsType
  translate(key: TranslationKey, params?: TranslationParams, count?: number): string
}

export default defineNuxtPlugin({
  name: 'app:i18n',
  enforce: 'pre',
  dependsOn: ['pinia', 'stores'],
  async setup(nuxtApp) {
    const fallback = useRuntimeConfig().public.translations.fallback
    const baseTranslations = fallback ? (await import('~/translations/master')).default : {}

    const confData = useConfdata()
    const translations = computed(() => merge({}, baseTranslations, confData.translations) as typeof TranslationsType)

    function translate(key: TranslationKey, params: TranslationParams = {}, count?: number) {
      const textResource = getByPath(translations.value as unknown, key) as string | undefined

      return typeof textResource === 'string'
        ? replacePlaceholders(pluralize(textResource, count), { ...params, count: count ?? params.count })
        : [count, key.split('.').at(-1)].filter(Boolean).join(' ')
    }

    const locale = computed(() => confData.locale ?? '')

    const $l10n = reactive({
      locale,
      language: computed(() => confData.language ?? ''),
      translations,
      translate,
    })

    const $t: TranslateFn = (key: TranslationKey, params?: number | TranslationParams, plural?: number) => {
      return typeof params === 'number' ? translate(key, {}, params) : translate(key, params, plural)
    }

    const $i18nMimic = reactive({
      t: $t,
      locale,
    })

    nuxtApp.provide('i18n', $i18nMimic)
    nuxtApp.provide('t', $t)
    nuxtApp.provide('l10n', $l10n)
  },
})

type I18NMimic = {
  t: TranslateFn
  locale: string
}

declare module '#app' {
  interface NuxtApp {
    readonly $t: TranslateFn
    readonly $l10n: L10NContext
    readonly $i18n: I18NMimic
  }
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    readonly $t: TranslateFn
    readonly $l10n: L10NContext
    readonly $i18n: I18NMimic
  }
}

declare module 'vue' {
  interface ComponentCustomProperties {
    readonly $t: TranslateFn
    readonly $l10n: L10NContext
    readonly $i18n: I18NMimic
  }
}
