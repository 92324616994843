import type { IBrowser, ICPU, IEngine, IOS } from 'ua-parser-js'
import UAParser from 'ua-parser-js'

export interface UserAgent {
  browser: IBrowser
  device: 'mobile' | 'desktop'
  os: IOS
  cpu: ICPU
  engine: IEngine
  supported: boolean
}

export const useUseragentStore = defineStore('useragent', () => {
  const useragent = ref(init())

  function init() {
    if (import.meta.server) {
      const useragentString = (useParams().all.browser ? useParams().all.browser : useRequestHeaders()['user-agent'])?.toString()

      if (!useragentString) {
        return
      }
      const ua = UAParser(useragentString)
      const result: UserAgent = {
        browser: ua.browser,
        device: devicetype(ua.device.type),
        os: ua.os,
        cpu: ua.cpu!,
        engine: ua.engine!,
        supported: supported[(ua.browser.name || 'X') as keyof typeof supported] >= parseInt(ua.browser.version || '0'),
      }
      return result
    }
  }

  function update(ua: UserAgent): void {
    useragent.value = ua
  }

  return { useragent, update }
})

const supported = {
  Chrome: 88,
  Firefox: 91,
  Safari: 14,
  Edge: 88,
  'Internet Explorer': 12,
  IOS: 12,
  'Samsung Internet': 16,
  Opera: 82,
  'Android Browser': 88,
  X: -1,
}

function devicetype(type?: string) {
  switch (type) {
    case 'wearable':
    case 'mobile':
      return 'mobile'
    case 'console':
    case 'tablet':
    case 'smarttv':
    case 'embedded':
    case undefined:
    default:
      return 'desktop'
  }
}
