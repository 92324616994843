/*
 * Tracking-Spezifikation:
 * https://wiki.hotelplan.com/display/TSICHDDWEBCC/04+-+Search+Results
 */

export default {
  sorting,
  toggleBookmark,
  objectMap,
  generalMap,
  paging,
  headerImpression,
  objectImpression,
  freeCancellationImpression,
  topRegionsImpression,
  topPlacesImpression,
  seoTextImpression,
  requestTravelguide,
  topNaturalSightsRequest,
  topNaturalSightsImpression,
  seoContentImpression,
  footerImpression,
  labelImpression,
  objectTeaserViewed,
  objectTeaserClicked,
  weatherInfoImpression,
  articleTeaserVisible,
  articleTeaserScrollClick,
  articleTeaserDetails,
  results,
  resultCount,
  datesArrivalSelect,
  datesDurationSelect,
  datesModeSwitch,
  datesDurationSelectFlex,
  datesClear,
  datesApply,
}

function sorting(criterion?: SortingOptions) {
  useTracking().send({
    event: 'event',
    eventCategory: 'Search Results',
    eventAction: 'Sorting',
    eventLabel: criterion ?? 'default',
    eventNonInteraction: false,
  })
}

function toggleBookmark({ accom, state }: { accom: string; state: boolean }) {
  useTracking().send({
    event: 'event',
    eventCategory: 'Search Results',
    eventAction: state ? 'Add to Wishlist' : 'Delete from Wishlist',
    eventLabel: accom,
    eventNonInteraction: false,
  })
}

function objectMap(state: boolean) {
  useTracking().send({
    event: 'event',
    eventCategory: 'Search Results',
    eventAction: 'View Options',
    eventLabel: state ? 'Object Map Opened' : 'Object Map Closed',
    eventNonInteraction: false,
  })
}

function generalMap(state: boolean) {
  useTracking().send({
    event: 'event',
    eventCategory: 'Search Results',
    eventAction: 'View Options',
    eventLabel: state ? 'General Map Opened' : 'General Map Closed',
    eventNonInteraction: false,
  })
}

function paging({ current, total }: { current: number; total: number }) {
  useTracking().send({
    event: 'event',
    eventCategory: 'Search Results',
    eventAction: 'Paging',
    eventLabel: current === total ? `${current} - Last` : current,
    eventNonInteraction: false,
  })
}

function headerImpression() {
  useTracking().send({
    event: 'event',
    eventCategory: 'Search Results',
    eventAction: 'Scroll Depth',
    eventLabel: 'Header viewed',
    eventNonInteraction: true,
  })
}

function objectImpression() {
  useTracking().send({
    event: 'event',
    eventCategory: 'Search Results',
    eventAction: 'Scroll Depth',
    eventLabel: 'Object seen',
    eventNonInteraction: true,
  })
}

function freeCancellationImpression() {
  return {
    event: 'event',
    eventCategory: 'Search Results',
    eventAction: 'Free cancellation viewed',
    eventLabel: 'Free cancellation',
    eventNonInteraction: true,
  }
}

function topRegionsImpression() {
  useTracking().send({
    event: 'event',
    eventCategory: 'Search Results',
    eventAction: 'Scroll Depth',
    eventLabel: 'Top Regions viewed',
    eventNonInteraction: true,
  })
}

function topPlacesImpression() {
  useTracking().send({
    event: 'event',
    eventCategory: 'Search Results',
    eventAction: 'Scroll Depth',
    eventLabel: 'Top Places viewed',
    eventNonInteraction: true,
  })
}

function seoTextImpression(options: { arg: string }) {
  useTracking().send({
    event: 'event',
    eventCategory: 'Search Results',
    eventAction: 'Scroll Depth',
    eventLabel: 'SEO Text viewed ' + options.arg, // (options.ratio < 0.5 ? '[half]' : '[full]'),
    eventNonInteraction: true,
  })
}

function _trackContentAssets(eventAction: string, eventNonInteraction = false) {
  useTracking().send({
    event: 'event',
    eventCategory: 'Search Results',
    eventAction,
    eventLabel: 'SEO content assets',
    eventNonInteraction,
  })
}

function requestTravelguide() {
  _trackContentAssets('Request travelguide')
}

function seoContentImpression() {
  _trackContentAssets('SEO content assets', true)
}

function topNaturalSightsRequest() {
  _trackContentAssets('Request top natural sights')
}

function topNaturalSightsImpression() {
  _trackContentAssets('Top natural sights', true)
}

function weatherInfoImpression() {
  _trackContentAssets('Weather data', true)
}

function articleTeaserVisible() {
  _trackContentAssets('Block article teaser', true)
}

function articleTeaserScrollClick(direction: ScrollDirectionHorizontal) {
  _trackContentAssets(`${direction === 'left' ? 'Previous' : 'Next'} block article teaser`)
}
function articleTeaserDetails() {
  _trackContentAssets('Request full block article')
}

function labelImpression({ params }: { params: { name: string } }) {
  if (useConfdata().pagetype !== 'search') {
    return
  }
  useTracking().send({
    event: 'Label',
    eventCategory: 'Search Results',
    eventAction: 'Label',
    eventLabel: `displayed + ${params.name} + ${getVirtPath(useConfdata().destination!)}`,
    eventNonInteraction: true,
  })
}

function footerImpression() {
  useTracking().send({
    event: 'event',
    eventCategory: 'Search Results',
    eventAction: 'Scroll Depth',
    eventLabel: 'Footer viewed',
    eventNonInteraction: true,
  })
}
function getVirtPath(destination: Destination) {
  let virtpath = '/searchresults/'
  if (destination.countryCode) {
    virtpath += destination.countryCode + '/'
    if (destination.regionCode) {
      virtpath += destination.regionCode + '/'
      if (destination.placeCode) {
        virtpath += destination.placeCode + '/'
      }
    }
  }
  return virtpath
}

const OBJECT_TEASER_TYPE_NAMES: Record<ObjectTeaserType, string> = {
  destination: '',
  pool: 'swimming-pool',
  sea: 'beach-sea',
  pets: 'pet-allowed',
  skiing: 'skiing-snowboard',
  familyfriendly: 'family-friendly',
}

const OBJECT_TEASER_BLOCK_NAMES: Record<ObjectTeaserBlock, string> = {
  reviews: 'Customer reviews',
  sustainable: 'Sustainable',
  wlan: 'Wlan',
  parking: 'Parking',
  aircondition: 'Aircondition',
  hiking: 'Hiking',
  fireplace: 'Fireplace',
  cheapcheap: 'Cheapcheap',
  // TODO: Verify that the tracking names for `sea` and `pool` are correct
  sea: 'Beach sea',
  pool: 'Swimming pool',
}

function objectTeaserViewed(params: { data: { type: ObjectTeaserType; block: ObjectTeaserBlock } }) {
  useTracking().send({
    event: 'object_teasers',
    action: 'displayed',
    label: OBJECT_TEASER_BLOCK_NAMES[params.data.block]?.toLocaleLowerCase(),
    category: OBJECT_TEASER_TYPE_NAMES[params.data.type].length ? OBJECT_TEASER_TYPE_NAMES[params.data.type] : undefined,
    component: 'object-teasers',
    position: 'SRP',
    language: useL10N().language,
    _clear: true,
  })
  useTracking().send({
    event: 'event',
    eventCategory: 'Search Results',
    eventAction: `${OBJECT_TEASER_BLOCK_NAMES[params.data.block]} viewed`,
    eventLabel: `Object teasers ${OBJECT_TEASER_TYPE_NAMES[params.data.type]}`.trim(),
    eventNonInteraction: true,
  })
}

function objectTeaserClicked({ type, block }: { type: ObjectTeaserType; block: ObjectTeaserBlock }) {
  useTracking().send({
    event: 'object_teasers',
    action: 'view accommodation',
    label: OBJECT_TEASER_BLOCK_NAMES[block]?.toLocaleLowerCase(),
    category: OBJECT_TEASER_TYPE_NAMES[type].length ? OBJECT_TEASER_TYPE_NAMES[type] : undefined,
    component: 'object-teasers',
    position: 'SRP',
    language: useL10N().language,
    _clear: true,
  })
  useTracking().send({
    event: 'event',
    eventCategory: 'Search Results',
    eventAction: `${OBJECT_TEASER_BLOCK_NAMES[block]} viewed request accommodation`,
    eventLabel: `Object teasers ${OBJECT_TEASER_TYPE_NAMES[type]}`.trim(),
    eventNonInteraction: false,
  })
}

function results(destination: Destination, trip: { checkin?: Date | string; checkout?: Date | string; duration?: number }, total: number) {
  const { countryEN, regionEN, placeEN, country, region, place } = destination
  const { checkin, checkout, duration } = trip

  useTracking().send({
    event: 'ga4_event',
    event_name: 'search_results',
    search_results_country: countryEN || country || null,
    search_results_region: regionEN || region || null,
    search_results_city: placeEN || place || null,
    search_results_count: total || 0,
    search_start_date: checkin ? toServerDate(checkin) : null,
    search_end_date: checkout ? toServerDate(checkout) : null,
    search_duration: duration || (checkin && checkout ? calculateDuration(checkin, checkout) : null),
  })
}

function resultCount(count: number) {
  useTracking().send({
    event: 'event',
    eventCategory: 'Search Results Counter',
    eventAction: 'Count',
    eventLabel: count,
    eventNonInteraction: false,
  })
}

function _detectSearchType(pagetype: PageType | undefined) {
  return pagetype === 'start' ? 'Quick Search' : pagetype === 'search' ? 'Facette Search' : ''
}

function datesArrivalSelect(date: Date | string, flexible = false) {
  const searchType = _detectSearchType(useConfdata().pagetype)

  if (!searchType) return

  useTracking().send({
    event: 'quick search',
    eventCategory: searchType,
    eventAction: 'Date',
    eventLabel: (flexible ? 'Calendar flexible ' : '') + toDateFormat(date, 'yyyy/MM'),
    eventNonInteraction: false,
  })
}

function datesDurationSelect(number: number, flexible = false) {
  const searchType = _detectSearchType(useConfdata().pagetype)

  if (!searchType) return

  useTracking().send({
    event: 'quick search',
    eventCategory: searchType,
    eventAction: 'duration',
    eventLabel: flexible ? `Calendar flexible ${number}` : number,
    eventNonInteraction: false,
  })
}

function datesModeSwitch(flexible: boolean) {
  const searchType = _detectSearchType(useConfdata().pagetype)

  if (!searchType) return

  useTracking().send({
    event: 'quick search',
    eventCategory: searchType,
    eventAction: flexible ? 'Open calendar flexible' : 'Open calendar',
    eventLabel: flexible ? 'Calendar flexible' : 'Calendar datepicker',
    eventNonInteraction: false,
  })
}

function datesDurationSelectFlex(duration: number) {
  const searchType = _detectSearchType(useConfdata().pagetype)

  if (!searchType) return

  useTracking().send({
    event: 'quick search',
    eventCategory: searchType,
    eventAction: `Select time-frame ${duration === 7 ? 'one week' : duration === 14 ? 'two week' : 'custom'}`,
    eventLabel: `Calendar flexible ${duration === 7 ? 'one week' : duration === 14 ? 'two week' : 'custom'}`,
    eventNonInteraction: false,
  })
}

function datesClear(flexible: boolean) {
  const searchType = _detectSearchType(useConfdata().pagetype)

  if (!searchType) return

  useTracking().send({
    event: 'quick search',
    eventCategory: searchType,
    eventAction: 'Clear dates',
    eventLabel: flexible ? 'Calendar flexible clear dates' : 'Calendar datepicker clear dates',
    eventNonInteraction: false,
  })
}

function datesApply(flexible: boolean) {
  const searchType = _detectSearchType(useConfdata().pagetype)

  if (!searchType) return

  useTracking().send({
    event: 'quick search',
    eventCategory: searchType,
    eventAction: 'Apply choice',
    eventLabel: flexible ? 'Calendar flexible apply choice' : 'Calendar datepicker apply choice',
    eventNonInteraction: false,
  })
}
