export const useNavigationStore = defineStore('navigation', () => {
  function navigate(path: string | undefined, queryparams: Params, options?: { replace?: boolean }) {
    const url = new URL(window.location.href)

    // Combine page-agnostic parameters with page-specific parameters
    const { redirect, persistent } = useParams()
    url.search = new URLSearchParams({
      ...asQueryParams(unpack(queryparams)),
      ...asQueryParams(path === undefined && useAB().isActive('keepRedirectParams') ? redirect : persistent),
    }).toString()

    if (path !== undefined) {
      url.pathname = path
    }
    if (!url.pathname.endsWith('/')) {
      url.pathname += '/'
    }

    // Only trigger routing if there has been an actual change
    if (url.pathname !== window.location.pathname || url.search !== window.location.search) {
      if (options?.replace) {
        history.replaceState(null, '', url)
      } else {
        history.pushState({}, '', url)
      }
    }
  }

  function navigateToURL(url: URL) {
    navigate(url.pathname, Object.fromEntries(url.searchParams))
  }

  function changeQuery(queryparams: Params) {
    navigate(undefined, queryparams, { replace: true })
  }

  return { navigate, navigateToURL, changeQuery }
})
