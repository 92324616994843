export const useReviewsStore = defineStore('reviews', () => {
  const rating = ref<ReviewsRating>()
  const reviews = ref<ReviewsSearchResultItem[]>([])
  const reviewCount = ref(0)
  const totalPages = ref(0)
  const loading = ref(false)

  async function translate(review: ReviewTranslationParams): Promise<ReviewTranslation | null> {
    const { data, error } = await useWebccApi().translate(review)

    if (error.value) return null

    return data.value ?? null
  }

  async function loadReviews(
    page: number,
    geo: { country?: string; region?: string; place?: string },
    locale: string = useL10N().language,
    pagesize: number = 20,
    bestReviews: boolean = false,
  ) {
    // console.log('this is loadReviews')
    // const additionalBody = useConfdata().additionalBody as ReviewspageBody
    // console.log(useConfdata().additionalBody)

    // console.log('additionalBody.Resource.destinationSlugEn: ', additionalBody.Resource.destinationSlugEn)

    // const geo = additionalBody.Resource.destinationSlugEn.split('/')
    // const [country, region, place] = geo

    const { data, error, pending } = await useWebccApi().reviews({
      ...parseURL(),
      ...removeFalsy(geo),
      ...useParams().all,
      bestReviews,
      pagesize,
      locale, // Doesn't affect anything. Should be `language` param?
      page,
    })

    loading.value = pending.value

    if (error.value) return

    reviews.value = data.value?.reviews || []
    rating.value = data.value?.rating
    reviewCount.value = data.value?._stats.totalHits || 0
    totalPages.value = Math.ceil(reviewCount.value / pagesize)
  }

  async function fetch(params: Params, options?: { bestReviews?: boolean; page?: number; pagesize?: number }) {
    return await useWebccApi().reviews({
      locale: useL10N().locale,
      ...params,
      ...(options ?? {}),
    })
  }

  // TODO: add implementation for searchfilterconf in search middleware
  function parseURL() {
    const country = useConfdata().destination?.countryCode as string
    const region = useConfdata().destination?.regionCode as string
    const place = useConfdata().destination?.placeCode as string
    const query = Object.assign({ country, region, place }, useParams().persistent)
    const pathelements = useRequestURL()
      .pathname.split('/')
      .filter((p) => !!p)
    const searchfilterconf = (useConfdata().additionalBody as ReviewspageBody).searchfilterconfs
    for (const conf of searchfilterconf || []) {
      if (pathelements.includes(conf.slug.path)) {
        const filtername = conf.filter
        if (['sea', 'lake', 'ski', 'center', 'pax'].includes(filtername)) {
          query[conf.filter] = conf.slug.value
        } else {
          query[conf.filter] = true
        }
      }
    }
    // falsy Werte löschen
    Object.keys(query).forEach((key) => !query[key] && delete query[key])
    return query
  }

  return { rating, reviews, loading, reviewCount, totalPages, loadReviews, fetch, translate }
})
