import type { Handlers } from '~/resources/tracking'

export const useTrackingStore = defineStore('tracking', () => {
  const options: Ref<{ handlers?: Record<string, Function>; disabled?: boolean; gtmID?: string }> = ref({})
  const handlers: Ref<Handlers | null> = ref(null)

  function init(h: Handlers, pageloadHandler: Function) {
    if (import.meta.server) {
      return
    }
    const config = useRuntimeConfig()
    const consent = useConsent()
    window.dataLayer = window.dataLayer || []

    handlers.value = h

    options.value = Object.assign({ ...config.public.tracking }, { h })

    window.dataLayer.push(pageloadHandler(config.public.tracking.gtmID))

    if (consent) {
      const initialConsent = consent.consent
      if (initialConsent) {
        trackConsentDefault(initialConsent)
      }
    }

    if (!options.value.disabled && useParams().all.tracking !== false && options.value.gtmID) {
      loadGTM(options.value.gtmID)
    }
  }

  function send(element: TrackingElement | TrackingElement[] | Record<string, unknown> | Record<string, unknown>[]) {
    if (!Array.isArray(element)) {
      element = [element]
    }
    ;(element as Array<Record<string, unknown>>).forEach((e) => {
      if (window.dataLayer && !options.value.disabled) {
        window.dataLayer.push(e)
      }
    })
  }

  function trackConsentDefault(consent: Consent) {
    // Tracking user consent only once at page load
    if (!window.dataLayer.some((item) => item.consentStatistics !== undefined)) {
      window.dataLayer.push(createConsentEventData(consent))
    }

    // Tracking consent defaults only once at page load
    if (!window.dataLayer.some((item) => item[0] === 'consent' && item[1] === 'default')) {
      const { gtag } = useGtag()

      gtag('consent', 'default', createConsentDetailedEventData(consent))
    }
  }

  function trackConsentUpdate(consent: Consent) {
    window.dataLayer.push({ event: 'user consent', ...createConsentEventData(consent) })

    const { gtag } = useGtag()

    gtag('consent', 'update', createConsentDetailedEventData(consent))
    if (!consent.statistics) {
      gtag('set', 'url_passthrough', true)
    }
  }

  return { handlers, init, send, trackConsentUpdate }
})

function useGtag() {
  return {
    gtag:
      window?.gtag ??
      /**
       * Define the `gtag` function in case `gtm.js` is not loaded yet and `window.gtag` is unavailable.
       * @see https://developers.google.com/tag-platform/security/guides/consent?consentmode=advanced#tag-manager_3
       */
      function gtag() {
        // eslint-disable-next-line prefer-rest-params
        window.dataLayer?.push(arguments as unknown as Record<string, unknown>)
      },
  }
}

function loadGTM(gtmID: string) {
  window.dataLayer.push({
    event: 'gtm.js',
    'gtm.start': new Date().getTime(),
    eventCallback(id: string) {
      if (id === gtmID && window.gaGlobal) {
        // Only set client id in projects that need it
        if (useIDs().client !== undefined) {
          useIDs().client = window.gaGlobal.vid
        }
      }
    },
  })

  const script = document.createElement('script')
  script.async = true
  script.src = `https://www.googletagmanager.com/gtm.js?id=${gtmID}`
  document.body.appendChild(script)
}

function createConsentEventData({ other, statistics }: Consent) {
  return {
    consentStatistics: statistics,
    consentRetargeting: other,
  }
}

function createConsentDetailedEventData({ other, statistics }: Consent) {
  return {
    ad_user_data: other ? 'granted' : 'denied',
    ad_personalization: other ? 'granted' : 'denied',
    ad_storage: other ? 'granted' : 'denied',
    analytics_storage: statistics ? 'granted' : 'denied',
  }
}
